<template>
  <div class="flex w-full justify-between">
    <div
      v-for="index in countItem"
      :key="index"
      class="mx-auto mb-5 h-[197px] w-[328px] rounded-2xl bg-gray-300 lg:mx-4 lg:mb-8 lg:h-[259px] lg:w-4/12 lg:rounded-3xl"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import useClientTypeStore from "~/stores/clientType";
const clientTypeStore = useClientTypeStore();
const { isDesktop } = storeToRefs(clientTypeStore);
const countItem = computed(() => {
  return isDesktop.value ? 3 : 1;
});

defineOptions({
  name: "PromotionSkeleton",
});
</script>
